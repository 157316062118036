<template>
  <v-container class="general list">
    <page-title
      :component="'UsersPage'"
      :translatable-tooltip="$t('group_user_list_page.page_title_tooltip')"
    >
      <template v-slot:actions>
        <v-text-field
          v-model="search"
          flat
          light
          solo
          hide-details
          class="search-input body-1"
        >
          <template slot="append">
            <font-awesome-icon
              :icon="['fas', 'search']"
              style="font-size: 18px"
              class="ml-1 sw-accent"
            ></font-awesome-icon>
          </template>
        </v-text-field>
        <VideoTutorial
          :component="'UsersPage'"
          :title="$t('users-tutorial-title')"
          class="ml-3"
        />
      </template>
    </page-title>

    <div v-if="totalUsers" :class="{ disabled: isLoading }">
      <v-layout class="row wrap align-center pa-4">
        <font-awesome-icon
          :icon="selectedAllIcon"
          style="font-size: 19px"
          class="sw-accent cursor-pointer"
          @click="toggleSelectedAll()"
        />
        <span class="ml-2 cursor-pointer" @click="toggleSelectedAll()">
          {{
            selectedAllState === 1
              ? $t("common.select_all")
              : $t("common.unselect_all")
          }}
        </span>
      </v-layout>

      <v-layout
        v-if="selectedAllState === 2 || selectedAllState === 3"
        class="row wrap align-center px-4 pb-4"
      >
        <template v-if="selectedAll && selectedAllState === 3">
          <font-awesome-icon
            :icon="['fa', 'trash']"
            style="font-size: 16px"
            class="sw-accent cursor-pointer"
            @click="deleteUsers('delete_all_except_selected', [])"
          />
          <span
            class="ml-2 cursor-pointer"
            @click="deleteUsers('delete_all_except_selected', [])"
          >
            {{
              totalUsers > 1
                ? $t("group_user_list_page.delete_selected_users_multi", {
                    delete_count: totalUsers,
                  })
                : $t("group_user_list_page.delete_selected_users_single")
            }}
          </span>
        </template>
        <template v-if="selectedAll && selectedAllState === 2">
          <font-awesome-icon
            :icon="['fa', 'trash']"
            style="font-size: 16px"
            class="sw-accent cursor-pointer"
            @click="deleteUsers('delete_all_except_selected', selectedUsers)"
          />
          <span
            class="ml-2 cursor-pointer"
            @click="deleteUsers('delete_all_except_selected', selectedUsers)"
          >
            {{
              totalUsers - selectedUsers.length > 1
                ? $t("group_user_list_page.delete_all_users_except_multi", {
                    delete_count: totalUsers - selectedUsers.length,
                    keep_count: selectedUsers.length,
                  })
                : $t("group_user_list_page.delete_all_users_except_single", {
                    keep_count: selectedUsers.length,
                  })
            }}
          </span>
        </template>
        <template
          v-if="
            (!selectedAll && selectedAllState === 2) ||
            (!selectedAll && selectedUsers.length)
          "
        >
          <font-awesome-icon
            :icon="['fa', 'trash']"
            style="font-size: 16px"
            class="sw-accent cursor-pointer"
            @click="deleteUsers('delete_selected', selectedUsers)"
          />
          <span
            class="ml-2 cursor-pointer"
            @click="deleteUsers('delete_selected', selectedUsers)"
          >
            {{
              selectedUsers.length > 1
                ? $t("group_user_list_page.delete_selected_users_multi", {
                    delete_count: selectedUsers.length,
                  })
                : $t("group_user_list_page.delete_selected_users_single")
            }}
          </span>
        </template>
      </v-layout>

      <v-layout
        v-if="(!selectedAll && selectedUsers.length) || selectedAllState === 2"
        class="px-4 pb-4"
      >
        <v-flex xs12>
          <span v-for="(user, i) in selectedUsers" :key="`user-${i}`">
            <span
              ><template v-if="i > 0">,</template> {{ user.full_name }}</span
            >
          </span>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>
    </div>

    <v-layout :style="{ width: '100%' }" class="row wrap align-center px-4">
      <v-flex class="xs12">
        <v-layout class="row align-center">
          <v-flex class="xs1"> </v-flex>
          <v-flex class="xs11 py-4">
            <v-layout class="row align-center">
              <v-flex class="xs3 caption">
                <span class="font-weight-medium table-title">
                  {{ $t("group_user_list_page.name_table_title") }}
                </span>
              </v-flex>
              <v-flex class="xs2 caption">
                <span class="font-weight-medium table-title">
                  {{ $t("group_user_list_page.phone_table_title") }}
                </span>
              </v-flex>
              <v-flex class="xs3 caption">
                <span class="font-weight-medium table-title">
                  {{ $t("group_user_list_page.email_table_title") }}
                </span>
              </v-flex>
              <v-flex class="xs2 caption">
                <span class="font-weight-medium table-title">
                  {{ $t("group_user_list_page.added_table_title") }}
                </span>
              </v-flex>
              <v-flex class="xs2 caption">
                <span class="font-weight-medium table-title">
                  {{ $t("group_user_list_page.registered_table_title") }}
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-divider></v-divider>

    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !users.length && !isSearching"
        class="row wrap mt-4 text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          <div v-if="!search">
            {{ $t("group_user_list_page.nothing_created") }}
          </div>
          <div v-if="search">
            {{ $t("group_user_list_page.nothing_found") }}
          </div>
        </v-flex>
      </v-layout>
    </transition>

    <template v-if="users && users.length">
      <v-layout
        v-for="(user, i) in users"
        :key="`user-${i}`"
        :style="{ width: '100%' }"
        class="row wrap align-center px-4"
        :class="{ disabled: isLoading }"
      >
        <v-flex class="xs12">
          <v-layout class="row align-center">
            <v-flex class="xs1">
              <font-awesome-icon
                :icon="
                  isSelected(user.id)
                    ? ['fa', 'check-square']
                    : ['far', 'square']
                "
                style="font-size: 19px"
                class="sw-accent cursor-pointer"
                @click="selectUser(user)"
              />
            </v-flex>
            <v-flex class="xs11 py-4">
              <v-layout class="row align-center">
                <v-flex class="xs3">
                  <a
                    class="sw-h5 font-weight-light"
                    @click.stop="editUser(user.id)"
                    >{{ user.first_name }} {{ user.last_name }}</a
                  >
                </v-flex>
                <v-flex class="xs2 caption grey--text">
                  <div v-if="user.phone_code && user.phone">
                    +{{ user.phone_code }} {{ user.phone }}
                  </div>
                </v-flex>
                <v-flex class="xs3 sw-caption grey--text">
                  {{ user.email }}
                </v-flex>
                <v-flex class="xs2 sw-caption grey--text">
                  {{ formatDate(user.signup_at) }}
                </v-flex>
                <v-flex class="xs2 sw-caption grey--text">
                  {{ formatDate(user.group_product_purchased_at) }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="xs12">
          <v-divider></v-divider>
        </v-flex>
      </v-layout>
    </template>
    <div v-if="totalPages > 1" class="text-center pt-4">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        @input="listUsers"
      ></v-pagination>
    </div>
    <template v-if="isPaidActionsSuspended">
      <v-btn fab class="ma-0 fab-disabled" disabled>
        <v-icon>add</v-icon>
      </v-btn>
    </template>
    <UserSubscribe
      ref="userSubscribe"
      :group-id="groupId"
      @success="listUsers"
    />
    <template v-if="!isPaidActionsSuspended">
      <app-speed-dial
        v-model="fab"
        :hint="$t('group_user_list_page.speed_dial_hint')"
        :class="{ disabled: isLoading }"
      >
        <template slot="items">
          <div class="px-3 py-2 speed-dial-items">
            <div class="py-2 text-right caption speed-dial-item">
              <span class="mr-3 item-title">{{ $t("addUserTooltip") }}</span>
              <v-avatar
                size="36"
                color="white"
                class="elevation-2 cursor-pointer"
                @click="openUserSubscribe"
              >
                <font-awesome-icon
                  icon="user-tie"
                  style="font-size: 19px"
                  class="sw-accent"
                />
              </v-avatar>
            </div>
            <div class="py-2 text-right caption speed-dial-item">
              <span class="mr-3 item-title">{{
                $t("importUsersTooltip")
              }}</span>
              <v-avatar
                size="36"
                color="white"
                class="elevation-2 cursor-pointer"
                @click="openUserImport"
              >
                <font-awesome-icon
                  icon="file-upload"
                  style="font-size: 19px"
                  class="sw-accent"
                />
              </v-avatar>
            </div>
          </div>
        </template>
      </app-speed-dial>
    </template>
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import UserSubscribe from "@/components/GroupUserListPage/UserSubscribe.vue";
import moment from "moment-timezone";

export default {
  data: () => ({
    isLoading: false,
    isSearching: false,
    users: [],
    fab: false,
    showTooltip: true,
    timeout: null,
    search: "",
    page: 1,
    totalPages: 0,
    totalVisible: 5,
    perPage: 16,
    totalUsers: 0,
    selectedAll: false,
    selectedUsers: [],
  }),
  computed: {
    ...mapGetters(["currentUser"]),
    groupId() {
      return this.$route.params.group_id;
    },
    isAdmin() {
      if (!this.currentUser) return false;
      if (!this.currentUser.roles) return false;

      return this.currentUser.roles.some((r) => r === "admin");
    },
    isPaidActionsSuspended() {
      return this.$store.getters.activeGroup
        ? this.$store.getters.activeGroup.paid_actions_suspended
        : true;
    },
    formatDate() {
      return (date) => {
        if (!date) return;
        return moment.tz(date, this.userTimezone).format("DD MMM YYYY HH:mm");
      };
    },
    selectedAllState() {
      if (this.selectedAll && !this.selectedUsers.length) {
        return 3;
      }

      if (!this.selectedAll && this.selectedUsers.length === this.totalUsers) {
        return 3;
      }

      if (
        this.selectedUsers.length &&
        this.selectedUsers.length !== this.totalUsers
      ) {
        return 2;
      }

      return 1;
    },
    selectedAllIcon() {
      if (this.selectedAllState === 1) {
        return ["far", "square"];
      }

      if (this.selectedAllState === 2) {
        return ["far", "minus-square"];
      }

      if (this.selectedAllState === 3) {
        return ["fa", "check-square"];
      }
    },
  },
  watch: {
    search() {
      this.isSearching = true;

      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.isSearching = false;
        this.page = 1;
        this.listUsers();
      }, 500);
    },
  },
  components: {
    UserSubscribe,
  },
  mounted() {
    this.listUsers();
  },
  methods: {
    toggleSelectedAll() {
      this.selectedAll = this.selectedAllState === 1 ? true : false;
      this.selectedUsers = [];
    },
    selectUser(user) {
      const index = this.selectedUsers.findIndex((el) => el.id === user.id);

      if (index !== -1) {
        this.selectedUsers.splice(index, 1);
        return;
      }

      const transformedUser = {
        id: user.id,
        full_name: `${user.first_name} ${user.last_name}`,
      };

      this.selectedUsers.push(transformedUser);
    },
    isSelected(id) {
      const user = this.selectedUsers.find((el) => el.id === id) || {};

      return this.selectedAll ? !user.id : !!user.id;
    },
    async listUsers() {
      try {
        const params = [
          this.groupId,
          {
            search: encodeURIComponent(this.search),
            page: this.page,
            per_page: this.perPage,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupUsers.list(...params);

        this.isLoading = false;

        if (!response) return;

        this.users = response.data.data;
        this.totalPages = response.data.pagination.total_pages;

        if (!this.search) {
          this.totalUsers = response.data.pagination.total;
        }
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async deleteUsers(mode, users) {
      let confirmationTitle = "";

      if (mode === "delete_selected" && users) {
        confirmationTitle =
          users.length > 1
            ? this.$t(
                "group_user_list_page.delete_all_users_multi_confirmation",
                { count: users.length },
              )
            : this.$t(
                "group_user_list_page.delete_all_users_single_confirmation",
              );
      }

      if (mode === "delete_all_except_selected" && users) {
        const deleteCount = this.totalUsers - users.length;

        confirmationTitle =
          deleteCount > 1
            ? this.$t(
                "group_user_list_page.delete_all_users_multi_confirmation",
                { count: deleteCount },
              )
            : this.$t(
                "group_user_list_page.delete_all_users_single_confirmation",
              );
      }

      const confirmationParams = [
        confirmationTitle,
        this.$t("common.no"),
        this.$t("common.yes"),
      ];

      const isConfirmed = await this.$refs.confirmationDialog.open(
        ...confirmationParams,
      );

      if (!isConfirmed) return;

      try {
        const params = {
          mode: mode,
          user_ids: users.length ? users.map((user) => user.id) : [""],
        };

        this.isLoading = true;

        await this.$api.groupUsers.delete_all(this.groupId, params);

        this.selectedUsers = [];
        this.selectedAll = false;
        this.page = 1;
        this.search = "";

        this.listUsers();
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    editUser(id) {
      if (!id) return;

      this.$router
        .push({
          name: "group_users_edit",
          params: {
            user_id: id,
          },
        })
        .catch(() => {});
    },
    openUserImport() {
      this.$router.push({
        name: "group_user_attribute_import",
        params: {
          group_id: this.groupId,
        },
      });
      this.fab = false;
    },
    openUserSubscribe() {
      if (!this.$refs.userSubscribe) return;
      this.$refs.userSubscribe.openDialog();
      this.fab = false;
    },
  },
};
</script>

<style lang="scss">
.speed-dial-items {
  border: 1px solid $accent;
  background: #fff;
  margin-right: -16px;
  margin-bottom: 16px;
  border-radius: 8px;

  .speed-dial-item {
    white-space: nowrap;

    .item-title {
      white-space: nowrap;
    }
  }
}

.fab-disabled {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 4;
}

.table-title {
  font-size: 14px;
}

.disabled {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
