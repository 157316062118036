<template>
  <div class="country-code-selector">
    <v-combobox
      ref="dialCode"
      v-model="dialCode"
      item-value="dialCode"
      :items="dialCodes"
      :filter="filter"
      :search-input.sync="searchInput"
      auto-select-first
      :label="label"
      hide-details
      :attach="attatched"
      @keydown="onKeyDown"
      :disabled="disabled"
    >
      <template v-slot:no-data>
        <div class="py-2 px-3 subheading">Nothing found</div>
      </template>
      <template slot="selection" slot-scope="data">
        <div :style="{ fontSize: '14px' }">
          <span
            v-if="data.item.iso2"
            :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"
          ></span>
          <span v-if="data.item.dialCode" class="font-weight-medium">
            +{{ data.item.dialCode }}
          </span>
        </div>
      </template>
      <template slot="item" slot-scope="data">
        <div :style="{ fontSize: '14px' }" class="slot-item">
          <span
            v-if="data.item.iso2"
            :class="`mr-2 flag-icon flag-icon-${data.item.iso2}`"
          ></span>
          <span v-if="data.item.dialCode" class="font-weight-medium">
            +{{ data.item.dialCode }}
          </span>
          <span v-if="data.item.name" class="ml-2">{{ data.item.name }}</span>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import "flag-icon-css/css/flag-icon.css";
import { countries } from "@/countries.js";

export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    attatched: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchInput: "",
  }),
  computed: {
    dialCode: {
      get() {
        return this.transformedCountryCodes.find(
          (el) => el.dialCode === this.value,
        );
      },
      set(val) {
        if (!val) {
          this.$emit("input", null);
        } else {
          this.$emit("input", val.dialCode);
        }
      },
    },
    sortedCountryCodes() {
      return [
        ...countries.filter((el) => el.default),
        ...countries.filter((el) => !el.default),
      ];
    },
    transformedCountryCodes() {
      return this.sortedCountryCodes.map((el) => ({
        dialCode: el.dialCode.toString(),
        iso2: el.iso2,
        name: el.name,
        plainText: `+${el.dialCode} ${el.name}`,
      }));
    },
    dialCodes() {
      return [
        { header: this.$t("common.type_to_search") },
        ...this.transformedCountryCodes,
      ];
    },
  },
  methods: {
    filter(item, queryText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const query = hasValue(queryText);

      return (
        item.plainText
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    onKeyDown(e) {
      if (!this.dialCode && e.code === "Enter") {
        return;
      }

      this.dialCode = null;
    },
  },
  watch: {
    dialCode(val) {
      if (!val) return;
      this.$nextTick(() => {
        this.$refs.dialCode.blur();
      });
    },
  },
};
</script>

<style lang="scss">
.country-code-selector .v-autocomplete__content {
  min-width: 300px !important;
}
</style>
